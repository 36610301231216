import React from 'react';
import '../assets/css/sidemenu.css';
import favicon from '../assets/img/favicon.png';
import { AiFillHome } from "react-icons/ai";
import { IoIosSettings } from "react-icons/io";
import { FaWarehouse } from "react-icons/fa6";
import { FaBook } from "react-icons/fa";

function Sidemenu() {
    return (
        <div className="sidebar">
            <div className="logo_sidemenu">
                <img src={favicon} alt="logo" />
            </div>

            <div className="menu_navigation">
                <ul className="u_menu">
                    <li className="">
                        <div className="u_menu-item">
                            <div className="u_menu-icon">
                                <div className="reacts_icon"><AiFillHome /></div>
                                <div className="u_menu-name">Home</div>
                            </div>
                        </div>
                    </li>
                    <li className="u_active-menu">
                        <div className="u_menu-item">
                            <div className="u_menu-icon">
                                <div className="reacts_icon"><IoIosSettings /></div>
                                <div className="u_menu-name">Configuration</div>
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <div className="u_menu-item">
                            <div className="u_menu-icon">
                                <div className="reacts_icon"><FaWarehouse /></div>
                                <div className="u_menu-name">Warehouse Mapping</div>
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <div className="u_menu-item">
                            <div className="u_menu-icon">
                                <div className="reacts_icon"><FaBook /></div>
                                <div className="u_menu-name">Masters</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>




            <div className="menuplsname">
                <div className="menus_icon">
                    <svg width="22" height="22" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="" xmlnsXlink="http://www.w3.org/1999/xlink"><ellipse class="u_fill" cx="5.097" cy="5.038" rx="3.097" ry="3.038" fill="#4E525F"></ellipse><ellipse class="u_fill" cx="5.097" cy="18" rx="3.097" ry="3.038" fill="#4E525F"></ellipse><ellipse class="u_fill" cx="5.097" cy="30.962" rx="3.097" ry="3.038" fill="#4E525F"></ellipse><ellipse class="u_fill" cx="17.897" cy="5.038" rx="3.097" ry="3.038" fill="#4E525F"></ellipse><ellipse class="u_fill" cx="17.897" cy="18" rx="3.097" ry="3.038" fill="#4E525F"></ellipse><ellipse class="u_fill" cx="17.897" cy="30.962" rx="3.097" ry="3.038" fill="#4E525F"></ellipse><ellipse class="u_fill" cx="30.903" cy="5.038" rx="3.097" ry="3.038" fill="#4E525F"></ellipse><ellipse class="u_fill" cx="30.903" cy="18" rx="3.097" ry="3.038" fill="#4E525F"></ellipse><ellipse class="u_fill" cx="30.903" cy="30.962" rx="3.097" ry="3.038" fill="#4E525F"></ellipse></svg>
                </div>
                <div className="username">
                    <div className="username_text">AA</div>
                </div>
            </div>

        </div>
    )
}

export default Sidemenu
