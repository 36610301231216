import React from 'react';
import '../assets/css/profile.css';

function Mainlayout() {
    return (
        <div>

            <div className="basic_info_box">



                <div className="basic_info_header">

                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div><h5>Create Warehouse</h5></div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 text-right">
                            <button type="button" className="btn btn-secondary mr-3">Discard</button>
                            <button type="button" className="btn btn-primary">Save</button>
                        </div>
                    </div>


                </div>
                <div className="basic_info_texts">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <input class="did-floating-input" type="text" placeholder=" " />
                                <label class="did-floating-label">Company ID</label>
                            </div>
                            {/* <div className="label_inputs">
                                <label className="display_label">Company ID <span className="required">*</span></label>
                                <input type="text" className="form-control modern_input" value="Company ID" />
                            </div> */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <input class="did-floating-input" type="text" placeholder=" " />
                                <label class="did-floating-label">Warehouse <span className="required">*</span></label>
                            </div>
                            {/* <div className="label_inputs">
                                <TextField id="outlined-basic" label="Warehouse" variant="outlined" className="modern_input" />
                            </div> */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <input class="did-floating-input" type="text" placeholder=" " />
                                <label class="did-floating-label">Warehouse Name <span className="required">*</span></label>
                            </div>
                            {/* <div className="label_inputs">
                                <label className="display_label">Warehouse Name <span className="required">*</span></label>
                                <input type="text" className="form-control modern_input" value="Warehouse Name" />
                            </div> */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <input class="did-floating-input" type="text" placeholder=" " />
                                <label class="did-floating-label">Warehouse Code <span className="required">*</span></label>
                            </div>
                            {/* <div className="label_inputs">
                                <label className="display_label">Warehouse Code <span className="required">*</span></label>
                                <input type="text" className="form-control modern_input" value="Warehouse Code" />
                            </div> */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <input class="did-floating-input" type="text" placeholder=" " />
                                <label class="did-floating-label">Warehouse Address 1 <span className="required">*</span></label>
                            </div>
                            {/* <div className="label_inputs">
                                <label className="display_label">Warehouse Address 1 <span className="required">*</span></label>
                                <input type="text" className="form-control modern_input" value="Warehouse Address 1" />
                            </div> */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <input class="did-floating-input" type="text" placeholder=" " />
                                <label class="did-floating-label">Warehouse Address 2 <span className="required">*</span></label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <input class="did-floating-input" type="text" placeholder=" " />
                                <label class="did-floating-label">Warehouse Address 3 <span className="required">*</span></label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <input class="did-floating-input" type="text" placeholder=" " />
                                <label class="did-floating-label">City <span className="required">*</span></label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">

                            <div class="did-floating-label-content">
                                <select class="did-floating-select">
                                    <option value="0">State</option>
                                </select>
                                <label class="did-floating-label">State</label>
                            </div>


                            {/* <div className="label_inputs">
                                <label className="display_label">State <span className="required">*</span></label>
                                <select className="form-control modern_input">
                                    <option value="0">Select State</option>
                                </select>
                            </div> */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <input class="did-floating-input" type="text" placeholder=" " />
                                <label class="did-floating-label">Goods and Services Tax <span className="required">*</span></label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <input class="did-floating-input" type="text" placeholder=" " />
                                <label class="did-floating-label">Permanent Account Number <span className="required">*</span></label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <select class="did-floating-select">
                                    <option value="0">Country</option>
                                </select>
                                <label class="did-floating-label">Country</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <input class="did-floating-input" type="text" placeholder=" " />
                                <label class="did-floating-label">Telephone <span className="required">*</span></label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <input class="did-floating-input" type="text" placeholder=" " />
                                <label class="did-floating-label">Fax <span className="required">*</span></label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div class="did-floating-label-content">
                                <select class="did-floating-select">
                                    <option value="0">Status</option>
                                </select>
                                <label class="did-floating-label">Status</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Mainlayout
