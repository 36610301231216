
import Header from './components/Header';
import Sidemenu from './components/Sidemenu.jsx';
import Mainlayout from './components/Mainlayout.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter, Routes } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <div className="main_layout">
      <div className="app_sidemenu">
        <Sidemenu />
      </div>
      <div className="app_layout">
        <Header />
        <Mainlayout />
      </div>


      {/* <BrowserRouter>

        <Routes>


        </Routes>
      </BrowserRouter> */}
    </div>
  );
}

export default App;
